
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  computed,
  ref,
} from "vue";
import { MrUiKitButton, MrUiKitModal } from "mr-ui-kit";
import { required } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import UploadFileService from "@/services/FileUploadService";
import { Document, UserRole } from "@/interfaces";
import HomeService from "@/services/HomeService";
import { DocumentType } from "@/interfaces";
import { useStore } from "vuex";

export default defineComponent({
  name: "AddFileModalBack",
  emits: ["submitForm", "close"],
  components: {
    MrUiKitButton,
    MrUiKitModal,
  },
  props: {
    mode: {
      default: "Aggiungi",
    },
    fileSelected: {
      type: Object as PropType<Document>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isMounted = ref(false);
    const dealerFormDisabled = ref(true);
    const store = useStore();

    const fileSimple = ref<any>({
      lastModified: null,
      lastModifiedDate: null,
      name: "Nick",
      size: 0,
      type: "",
      webkitRelativePath: "",
    });

    const fileSelected = computed(() => {
      let file: Document | null = null;

      if (props.fileSelected) {
        let newFile: any = props.fileSelected.file ?? null;

        if (newFile) {
          newFile.name = newFile.filename;
        }

        file = {
          type: props.fileSelected.type,
          objectTypes: props.fileSelected.objectTypes,
          name: props.fileSelected.name,
          link: props.fileSelected.link,
          file: newFile,
          fileId: props.fileSelected.file?.id,
          id: props.fileSelected.id,
          dealer: props.fileSelected.objectTypes.includes(UserRole.Dealer),
          noDealer: props.fileSelected.objectTypes.includes(UserRole.NoDealer),
          lastModified: null,
          size: 0,
          webkitRelativePath: "",
        };
      } else {
        file = null;
      }

      return file;
    });

    const manageToggle = () => {
      return props.fileSelected && props.fileSelected.type === "link"
        ? false
        : true;
    };
    const toggleState = ref(manageToggle());

    onMounted(() => {
      isMounted.value = true;
    });

    const items = [
      { label: "File", value: true },
      { label: "Link", value: false },
    ];

    const fileFields = reactive([
      {
        component: "MrUiKitSimpleFileUploader",
        name: "file",
        rules: {
          required: helpers.withMessage("File Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitToggle",
        name: "dealer",
        props: {
          label: "Carica per Concessionari",
        },
      },
      {
        component: "MrUiKitToggle",
        name: "noDealer",
        props: {
          label: "Carica per non Concessionari",
        },
      },
    ]);
    const linkFields = reactive([
      {
        component: "MrUiKitInput",
        name: "name",
        props: {
          label: "Nome",
        },
        rules: {
          required: helpers.withMessage("Nome Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitInput",
        name: "link",
        props: {
          label: "Link",
        },
        rules: {
          required: helpers.withMessage("Link Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitToggle",
        name: "dealer",
        props: {
          label: "Carica per Concessionari",
        },
      },
      {
        component: "MrUiKitToggle",
        name: "noDealer",
        props: {
          label: "Carica per non Concessionari",
        },
      },
    ]);

    const form =
      fileSelected.value ||
      reactive<Document>({
        type: DocumentType.FILE,
        objectTypes: [],
        name: "",
        link: "",
        dealer: false,
        noDealer: false,
        file: null,
      });

    const allowCheckout = () => {
      return form.dealer || form.noDealer;
    };

    onMounted(() => {
      isMounted.value = true;
    });

    const modifyFile = async () => {
      await HomeService.modifyFile(form);
    };

    const createFile = async () => {
      await HomeService.uploadFile(form);
    };

    const onSubmit = async () => {
      try {
        fileSelected.value ? await modifyFile() : await createFile();

        store.commit("setAlert", {
          type: "success",
          message: fileSelected.value
            ? "Modifica effettuata con successo"
            : "File caricato con successo",
        });

        emit("submitForm", "");
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops... c'è stato un errore.",
        });
      }
    };

    const onClose = () => {
      emit("close");
    };

    const toggleChange = () => {
      form.type = toggleState.value ? DocumentType.FILE : DocumentType.LINK;

      form.name = "";
      form.link = "";

      dealerFormDisabled.value = allowCheckout();
    };

    const onUpdateForm = async (event: any) => {
      dealerFormDisabled.value = allowCheckout();
      if (form == null) return;
      switch (event.type) {
        case "upload":
          /* eslint-disable no-case-declarations */
          const fileToUpload = form;
          if (fileToUpload) {
            const uploadedFile = await UploadFileService.uploadFile(form.file);
            form.link = uploadedFile.url!;
            form.file = uploadedFile;
            form.name = uploadedFile.filename!;
          }
          break;
        default:
          return;
      }
    };

    return {
      toggleState,
      items,
      form,
      fileFields,
      linkFields,
      isMounted,
      dealerFormDisabled,
      toggleChange,
      allowCheckout,
      onSubmit,
      onClose,
      onUpdateForm,
    };
  },
});
