import {
  RootState,
  SetupResponse,
  ContentText,
  Document,
  UserRole,
} from "@/interfaces";
import store from "@/store";
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import FilterService from "./FilterService";
import UserService from "./UserService";

class HomeService {
  async getHomeContentText(): Promise<ContentText[]> {
    // if (!UserService.isAdmin()) { return null }
    const { data }: AxiosResponse<ContentText[]> = await axios.get(
      "/admin/homes"
    );
    return data;
  }

  async setHomeContentText(id: number, content: string): Promise<any> {
    const response = await axios.patch<any>("/admin/homes/" + id, { content });
    return response.data;
  }

  async countAdminObjects(): Promise<any> {
    const response = await axios.get<any>("admin/homes/objects/count");
    return response.data.count;
  }

  async getAdminObjects(filter: any): Promise<any> {
    const parsed = FilterService.parseFilter(filter);
    const response = await axios.get<any>("admin/homes/objects" + "?" + parsed);
    return response.data;
  }

  async removeFile(fileId: number): Promise<any> {
    const response = await axios.delete<any>("admin/homes/objects/" + fileId);
    return response.data;
  }

  async uploadFile(file: Document): Promise<any> {
    const objectTypes = [];

    file.dealer ? objectTypes.push(UserRole.Dealer) : null;
    file.noDealer ? objectTypes.push(UserRole.NoDealer) : null;
    const fileToSend = {
      type: file.type,
      name: file.file ? file.file.filename : file.name,
      link: file.type == "file" ? file.file!.url : file.link,
      fileId: file.type == "file" ? file.file!.id : null,
      objectTypes,
    };
    const response = await axios.post<any>("/admin/homes/objects", fileToSend);
    return response.data;
  }

  async modifyFile(file: Document): Promise<any> {
    const objectTypes = [];
    file.dealer ? objectTypes.push(UserRole.Dealer) : null;
    file.noDealer ? objectTypes.push(UserRole.NoDealer) : null;

    file.objectTypes = objectTypes;

    const fileToSend = {
      type: file.type,
      name: file.file ? file.file.filename : file.name,
      link: file.type == "file" ? file.file!.url : file.link,
      fileId: file.type == "file" ? file.file!.id : null,
      objectTypes,
    };

    const response = await axios.patch<any>(
      "/admin/homes/objects/" + file.id,
      fileToSend
    );
    return response.data;
  }
}

export default new HomeService();
