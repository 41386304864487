
import { SetupResponse } from "@/interfaces";
import { defineComponent, ComputedRef, computed, ref } from "vue";
import { useStore } from "vuex";
import FileLink from "@/components/UI/FileLink.vue";
import UrlUtils from "@/utils/urlUtils";

export default defineComponent({
  name: "HomeFront",
  components: {
    FileLink,
  },
  setup() {
    const store = useStore();

    const urlUtils = new UrlUtils();

    const show = ref(false);

    const fileToShow = ref<any>([]);

    const content: ComputedRef<SetupResponse> = computed(() => {
      return store.getters.getContent;
    });
    const fileObjects: ComputedRef<SetupResponse> = computed(() => {
      return store.getters.getObjects.filter(
        (file: any) => file.type === "file"
      );
    });

    const linkObjects: ComputedRef<SetupResponse> = computed(() => {
      return store.getters.getObjects.filter(
        (file: any) => file.type === "link"
      );
    });

    const openLink = (item: any) => {
      window.open(urlUtils.validateURL(item.link), "_blank");
    };

    const dowloadFile = (item: any) => {
      fileToShow.value = [
        { src: urlUtils.validateURL(item.file.url), type: "pdf" },
      ];
      show.value = true;
    };

    return {
      content,
      fileObjects,
      linkObjects,
      show,
      fileToShow,
      dowloadFile,
      openLink,
    };
  },
});
