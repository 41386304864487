import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "greetings-text mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeBack = _resolveComponent("HomeBack")!
  const _component_HomeFront = _resolveComponent("HomeFront")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMounted)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, "Ciao " + _toDisplayString(_ctx.user.email), 1),
          (_ctx.user.isAdmin)
            ? (_openBlock(), _createBlock(_component_HomeBack, { key: 0 }))
            : (_openBlock(), _createBlock(_component_HomeFront, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}