
import { defineComponent, ref, reactive, onMounted, PropType } from "vue";
import {
  MrUiKitToggle,
  MrUiKitButton,
  MrUiKitForm,
  MrUiKitSideNav,
  MrUiKitCard,
} from "mr-ui-kit";
import { required } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import HomeService from "@/services/HomeService";
import { ContentText } from "@/interfaces";
import FilesTable from "@/components/Backoffice/Home/FilesTable.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "HomeBack",
  components: {
    MrUiKitToggle,
    MrUiKitForm,
    MrUiKitButton,
    MrUiKitSideNav,
    MrUiKitCard,
    FilesTable,
  },
  setup() {
    const toggleState = ref(true);
    const isMounted = ref(false);
    const store = useStore();
    const collapsed = ref(false);
    const contents = ref<ContentText[]>([]);

    const toggleCollapse = () => {
      collapsed.value = !collapsed.value;
    };

    const formValue = ref({
      content: "",
    });

    const fields = reactive([
      {
        component: "MrUiKitTextEditor",
        name: "content",
        rules: {
          required: helpers.withMessage(
            "Questa sezione non può essere vuota",
            required
          ),
        },
      },
    ]);

    const items = [
      { label: "Esercenti concessionari", value: true },
      { label: "Esercenti non concessionari", value: false },
    ];

    onMounted(async () => {
      await getContentText();
      const content: ContentText = contents.value.filter(
        (doc: ContentText) => doc.id === 1
      )[0];
      formValue.value.content = content.content;
      isMounted.value = true;
    });

    const getContentText = async () => {
      contents.value = await HomeService.getHomeContentText();
    };

    const onSubmit = async () => {
      try {
        await HomeService.setHomeContentText(
          toggleState.value ? 1 : 2,
          formValue.value.content
        );
        await getContentText();
        store.commit("setAlert", {
          type: "success",
          message: "Contenuto salvato con successo",
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops... c'è stato un errore.",
        });
      }
    };

    const manageId = () => {
      return toggleState.value === true ? 1 : 2;
    };

    const toggleChange = () => {
      const content: ContentText = contents.value.filter(
        (doc: ContentText) => doc.id === manageId()
      )[0];

      formValue.value.content = content.content;
    };

    return {
      items,
      fields,
      formValue,
      toggleState,
      isMounted,
      collapsed,
      toggleCollapse,
      onSubmit,
      toggleChange,
      manageId,
    };
  },
});
