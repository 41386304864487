
import {
  defineComponent,
  ref,
  PropType,
  computed,
  watch,
  onMounted,
  reactive,
} from "vue";
import { MrUiKitTable, MrUiKitButton } from "mr-ui-kit";
import { ContentText, Document, PaginationModel } from "@/interfaces";
import AddFileModalBack from "@/components/Backoffice/modals/AddFileModalBack.vue";
import HomeService from "@/services/HomeService";
import UrlUtils from "@/utils/urlUtils";
import DeleteModal from "@/components/Backoffice/modals/DeleteModal.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "FilesTable",
  components: {
    MrUiKitTable,
    MrUiKitButton,
    AddFileModalBack,
    DeleteModal,
  },
  props: {
    content: {
      type: Array as PropType<ContentText[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props: any) {
    const isMounted = ref(false);
    const addModalOpened = ref(false);
    const utils = new UrlUtils();
    const show = ref(false);
    const fileToShow = ref<any>([]);
    const deleteText = ref("");
    const deleteModalOpened = ref(false);
    const id = computed(() => {
      return props.id;
    });
    const objectsTable = ref<any>([]);
    const fileSelected = ref<Document | null>(null);
    const limit = ref(10);
    const store = useStore();

    const pagination = reactive<PaginationModel>({
      current: 1,
      count: 0,
      limit: limit.value,
    });

    const onLoad = async (offset: number) => {
      objectsTable.value = await HomeService.getAdminObjects({
        offset,
        limit: limit.value,
      });
    };

    const onInit = async () => {
      pagination.current = 1;

      pagination.count = await HomeService.countAdminObjects();
      await onLoad(0);
    };

    onMounted(async () => {
      await onInit();
      isMounted.value = true;
    });

    const toggleModal = async (file: Document) => {
      fileSelected.value = file ?? null;
      addModalOpened.value = !addModalOpened.value;
      await onLoad(0);
    };

    const fileColumns = ref([
      { label: "Id", field: "id" },
      { label: "Nome", field: "name" },
      { label: "Tipo", field: "type" },
      { label: "Conc.", field: "dealer" },
      { label: "Non Conc.", field: "no-dealer" },
      // { label: "Data", field: "date" },
      { label: "", field: "actions" },
    ]);

    const manageLabelDealer = (item: Document) => {
      return item.objectTypes.includes("dealer");
    };

    const manageLabelNoDealer = (item: Document) => {
      return item.objectTypes.includes("no-dealer");
    };

    const openBlob = (item: Document) => {
      const url: any = utils.validateURL(item.file!.url!);
      fileToShow.value = [{ src: url, type: "pdf" }];
      show.value = true;
    };

    const openLink = (item: Document) => {
      item.file
        ? openBlob(item)
        : window.open(utils.validateURL(item.link), "_blank");
    };

    const deleteModal = (file: Document) => {
      deleteText.value =
        "Vuoi davvero eliminare il file ''" + file.name + "''?";
      fileSelected.value = file;
      deleteModalOpened.value = !deleteModalOpened.value;
    };

    const deleteFile = async (file: Document) => {
      try {
        await HomeService.removeFile(file.id!);
        onLoad(0);
        deleteModalOpened.value = false;
      } catch (err: any) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops... c'è stato un errore.",
        });
      }
    };

    return {
      fileColumns,
      isMounted,
      objectsTable,
      addModalOpened,
      deleteFile,
      deleteText,
      deleteModal,
      deleteModalOpened,
      toggleModal,
      fileSelected,
      manageLabelDealer,
      manageLabelNoDealer,
      openLink,
      show,
      fileToShow,
      pagination,
      onLoad,
      limit,
    };
  },
});
