
import { User } from "@/interfaces";
import { defineComponent, computed, ComputedRef, onMounted, ref } from "vue";
import HomeFront from "@/components/Frontoffice/HomeFront.vue";
import HomeBack from "@/components/Backoffice/Home/HomeBack.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Home",
  components: {
    HomeBack,
    HomeFront,
  },
  setup() {
    const store = useStore();
    const isMounted = ref(false);

    const user: ComputedRef<User> = computed(() => {
      return store.getters.getUser;
    });

    onMounted(async () => {
      isMounted.value = true;
    });

    return {
      user,
      isMounted,
    };
  },
});
